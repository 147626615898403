<template>
  <el-select
    @change="sortFn"
    class="select-address m-r-16 selsectboxTop"
    :class="classselcet(sortValue)"
    v-model="sortValue"
    placeholder="请选择排序方式"
  >
    <el-option
      v-for="(item, inx) in sortList"
      :key="inx"
      :label="item.label"
      :value="item.value"
      :class="item.type == 1 ? 'topJt' : 'bottomJt'"
    > 
    </el-option>
  </el-select>
</template>

<script>
export default {
  data() {
    return {
      
      sortValue: '',
      // 发文数 or 粉丝数 排序
      sortList: [
        // {
        //   label: "发文数",
        //   field: "content_count",
        //   asc: false,
        //   type: 2,
        //   value: 2,
        // },
        // {
        //   label: "发文数", 
        //   field: "content_count",
        //   asc: true,
        //   type: 1,
        //   value: 1,
        // },
        { label: "粉丝数", field: "fans", asc: false, type: 2, value: 4 },
        { label: "粉丝数", field: "fans", asc: true, type: 1, value: 3 },
    
      ],
    };
  },
  methods: {
    getSortData() {
      let sort = {};
      this.sortList.forEach((v) => {
        if (v.value == this.sortValue) {
          sort.field = v.field;
          sort.asc = v.asc;
        }
      });
      return sort;
    },
    classselcet(v) {
      if(v == 1 || v==3) {
        return 'selsectboxBottom'
      }else {
        return 'selsectboxTop'
      }
    },
    sortFn() {
      this.$emit("sortFn");
    },
  },
};
</script>

<style scoped lang="scss">
  //替换左边默认图标
  .selsectboxTop ::v-deep.el-icon-arrow-up::before {
       content: "";
       background: url('../../../assets/img/gjh/topJianTou.png') center center no-repeat;
       position: absolute;
       width: 100%;
       height: 100%;
       top: 50%;
       left: 50%;
       transform: translate(-50%, -50%);
 }
 .selsectboxBottom ::v-deep.el-icon-arrow-up::before {
       content: "";
       background: url('../../../assets/img/gjh/bottomJianTou.png') center center no-repeat;
       position: absolute;
       width: 100%;
       height: 100%;
       top: 50%;
       left: 50%;
       transform: translate(-50%, -50%);
 }
.topJt{
  background-image: url('../../../assets/img/gjh/topJianTou.png') ;
  background-repeat:no-repeat ;
  background-position-x: 90%;
  background-position-y: center;
}
.bottomJt{
  background-image: url('../../../assets/img/gjh/bottomJianTou.png');
  background-repeat:no-repeat ;
  background-position-x: 90%;
  background-position-y: center;
}
.select-address {
  width: 190px;
  height: 36px;
  ::v-deep .el-input__inner {
    width: 190px;
    height: 36px;
    border-radius: 4px;
  }
  ::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ::v-deep .el-input__icon.el-icon-arrow-down {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ::v-deep .el-input__icon.el-icon-circle-close {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ::v-deep .el-cascader {
    height: 36px !important;
    margin-top: 0px;
    .el-input.el-input--suffix {
      height: 36px;
      display: flex;
      align-items: center;
    }
  }
}
</style>