<template>
  <div class="overall-page">
      <div class="dialog">
          <el-dialog :before-close='close' :close-on-click-modal='false' :title="title" :visible.sync="dialogFormVisible">
              <!-- 步骤条 -->
            <div class="steps">
                <div class="steps-box" v-for="(item,index) in 3" :key="index"> 
                     <div :class="index > stepIndex?'spot-two':'spot'"></div>
                    <div class="line"  v-if="index == stepIndex"></div>
                    <div class="line-two"  v-if="index == stepIndex"></div>
                    <div class="line-three"  v-if="index < stepIndex"></div>
                    <div class="line-four" v-if="index > stepIndex"></div>
                    <div :class="stepIndex == 3?'spot':'spot-two'" v-if="index == 2"></div>
                </div>
            </div>
            <div class="step-name">
                <div :class="index==stepIndex?'name2':'name'" v-for="(item,index) in stepList" :key="index" :style="index==3?'width:0rem':''">{{item}}</div>
            </div>
            <!-- 导入 -->
            <div class="type" v-if="stepIndex == 0 && importScussce">
                <!-- 下载模板 -->
                <div class="template" >
                    <div class="template-left" @click="download">
                        <img src="../../../assets/img/cutGraph/xiazai-2.png" alt="">
                    </div>
                    <div class="template-right">
                        <div class="text">填写导入数据信息</div>
                        <div class="tip">请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除</div>
                        <div @click="download" class="download">下载模板</div>
                    </div>
                </div>
                <!-- 上传 -->
                <el-upload
                    class="upload-demo"
                    ref="upload"
                    :action="`${baseURL}module-account/accountImportBatch/importStandingExcel`"
                    :limit="1"
                    :on-exceed='exceed'
                    :on-success='success'
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :on-change="change"
                    :headers="headerObj"
                    :show-file-list='false'
                    :file-list="fileList"
                    :before-upload="beforeAvatarUpload">
                    <div class="template">
                        <div class="template-left">
                            <img src="../../../assets/img/cutGraph/shangchuan.png" alt="">
                        </div>
                        <div class="template-right">
                            <div class="text">上传填好的信息表</div>
                            <div class="tip">文件后缀名必须为xls 或xlsx （即Excel格式），文件大小不得大于10M，最多支持导入3000条数据</div>
                            <div class="download">上传文件</div>
                        </div>
                    </div>
                    <!-- <el-button style="margin-left: 0.625rem;" size="small" type="success" @click="submitUpload">上传到服务器</el-button> -->
                </el-upload>
                <div class="file-list" v-for="(item,index) in fileList" :key="index">
                    <img src="../../../assets/img/cutGraph/Excel1.png" alt="">
                    <div class="name-box">
                        <div class="name-box-top">
                            <div class="name-box-top-left">
                               <div class="name">{{item.name}}</div>
                                <div class="size">({{item.size/1024 > 1024?(item.size/1024/1024).toFixed(1)+'MB':(item.size/1024).toFixed(1)+'KB'}})</div> 
                            </div>
                            <div class="proportion">{{uploadNumber}}%</div>
                        </div>
                        <div class="progsess" :style="`width:${uploadNumber}%`"></div>
                    </div>
                    <img class="rev" @click="revList" src="../../../assets/img/cutGraph/guanbi.png" alt="">
                </div>
            </div>
            <!-- 导入失败 -->
            <div class="type" v-if="stepIndex == 0 && !importScussce">
                <div class="fail">   
                    <div class="tip">
                        <img src="../../../assets/img/cutGraph/tishi-2.png" alt="">
                    </div>
                    <div class="fail-right">
                        <div class="normal">正常数据条数：<span>{{successNumber}}条</span></div>
                        <div class="abnormal">异常数据条数：<span>{{failNumber}}条</span></div>
                    </div>
                </div>
                <div class="prompt">
                    <div class="text">异常提示</div>
                    <div class="prompt-content" v-for="(item,index) in problemList" :key="index" :style="index==0?'margin-top:3.125rem':''">
                        <div class="travel">{{`第${item.numberOfRow}行`}}:</div>
                        <div class="problem">{{item.msg}}</div>
                    </div>
                </div>
            </div>
            <!-- 导入成功 -->
            <div class="type" v-if="stepIndex == 1">
                <div class="table" >
                    <el-table
                    v-loading='loading'
                    :data="tableData"
                    max-height='450px'
                    stripe
                    :header-cell-style="{padding:'0.25rem 0rem',color:' #737A94',height:'2.5rem','font-size':'0.75rem'}"
                    :cell-style="{padding:'0.25rem 0rem','font-weight': '500',color: '#000000',height:'2.5rem','font-size':'0.75rem' }"
                    style="width: 100%">
                    <el-table-column
                        prop="accountName"
                        label="账号名称">
                    </el-table-column>
                    <el-table-column
                        prop="accountId"
                        label="账号ID">
                    </el-table-column>
                    <el-table-column
                        prop="area"
                        label="所属区域">
                    </el-table-column>
                    <el-table-column
                        prop="bodyTypeName"
                        label="主体信息">
                    </el-table-column>
                    <el-table-column
                        prop="companyName"
                        label="主办单位">
                    </el-table-column>
                    <el-table-column
                        prop="fans"
                        label="粉丝数量">
                        <template slot-scope="scope">{{scope.row.fans?scope.row.fans:0}}</template>
                    </el-table-column>
                    <el-table-column
                        prop="icpNumber"
                        label="ICP备案">
                    </el-table-column>
                    <el-table-column
                    prop="labelIds"
                        label="标签">
                    </el-table-column>
                    <el-table-column
                    prop="messageTypesName"
                        label="信息类型">
                    </el-table-column>
                    <el-table-column
                    prop="contentCount"
                        label="发文数">
                    </el-table-column>
                    <el-table-column
                        prop="attention"
                        label="关注数">
                    </el-table-column>
                    <el-table-column
                    prop="platformTypeName"
                        label="平台类型">
                    </el-table-column>
                    </el-table>
                </div>
                <!-- 分页 -->
                <Pagination

                @CurrentChange='currentChange'
                :pageNumber='page.pageNumber'
                :pageSize='page.pageSize'
                :total='total'
                :layout="'prev, pager, next'"
                :count='5'
                >
                </Pagination>
            </div>
            <!-- 正在导入 -->
            <div class="type" v-if="stepIndex == 2">
                <div class="success">
                    <img src="../../../assets/img/cutGraph/zhengzaidaoru.png" alt="">
                </div>
                <el-progress class="progress" :percentage="number" ></el-progress>
                <div class="tip-text">正在导入数据</div>
            </div>
            <!-- 导入完成 -->
            <div class="type" v-if="stepIndex == 3">
                <div class="import-success">
                    <img src="../../../assets/img/cutGraph/success.png" alt="">
                </div>
                <div class="over">数据导入完成</div>
                <div class="info-num">您已成功导入{{successNumber}}条数据</div>
            </div>
            
            <div slot="footer" class="dialog-footer">
              <el-button  v-if="stepIndex == 0 && !importScussce" @click="newUpload">重新上传</el-button>
              <!-- 上传空数据 -->
              <el-button  v-if="stepIndex == 1 && total==0" @click="newUpload">重新上传</el-button>
              <el-button v-if="stepIndex < 2 && importScussce && total !=0" type="primary" @click="next">下一步</el-button>
            </div>
          </el-dialog>
      </div>
  </div>
</template>

<script>
import {queryById,list,synchInfo} from '../../../api/platformaccount/index' 
import Pagination from '@/components/pagination.vue'
// import Matrix from '@/api/matrixManagement/index.js'
export default {
    components:{Pagination},
    props:{
        dialogFormVisible:{
            type:Boolean,
            default:false
        },
        // platformType:{ //0：网站模板；1：其他类型模板
        //     type:Number,
        // },
        matrixId:{ //矩阵id
            type:Number,
        }
    },
    data(){
        return{
            title:'数据导入',//弹框标题
            stepIndex:0,//步骤
            stepList:['上传文件','数据预览','导入数据','导入完成'],
            fileList:[],
            importScussce:true,//导入是否成功
            tableData:[],
            baseURL: window.localStorage.getItem('baseURL'),//请求地址
            headerObj:{//上传请求头
              Authorization:'Bearer '+window.localStorage.getItem('token')
            },
            dataObj:{'platformType':this.platformType,'matrixId':this.matrixId},
            page:{
                pageNumber:1,
                pageSize:10
            },
            loading:false,
            successNumber:0,//成功导入条数
            failNumber:0,//失败条数
            batchId:null,//批次id
            uploadNumber:0,//上传比例
            total:1,//数据总数
            problemList:[],//异常提示
            number:0,
        }
    },
    created(){
        
    },
    methods:{
       
        //同步进度
        async progress(){
            setInterval(() => {
                if(this.number<99){
                    this.number++
                }else{
                    clearInterval()
                }
            }, 10);
            let res = await synchInfo(this.batchId)
            if(res.data.code == 200){
                setTimeout(() => {
                   this.number = 100
                    this.stepIndex++ 
                }, 1000);
            }
            
        },
        close(){ //关闭
            this.stepIndex = 0
            this.total = 1
            this.importScussce = true
            this.fileList = []
            this.problemList = []
            this.$emit('close')
        },
        next(){ //下一步
            if(this.fileList.length > 0){
                this.stepIndex ++
                switch (this.stepIndex) {
                    case 1:this.importScussce?this.getData():'' //上传成功调用列表
                        break;
                    case 2:this.progress()
                        break;
                }
            }else{
                this.$message.warning('请上传文件')
            }   
        },
        beforeAvatarUpload(file){
            var testmsg = file.name.substring(file.name.lastIndexOf('.')+1);
            let extentsion = testmsg === 'xls';
            let extentsion1 = testmsg === 'xlsx';
            const isLt2M = file.size / 1024 / 1024 < 10;
            if(!extentsion && !extentsion1) {
                this.$message({
                    message: '上传文件只能是 xls 或 xlsx 格式',
                    type: 'warning'
                })
                return false
            }
            if(!isLt2M) {
                this.$message({
                message: '上传文件大小不能超过 10MB!',
                type: 'warning'
                })
                return false
            }
        },
        handleRemove() {
        },
        handlePreview() {
        },
        currentChange(val){
            this.page.pageNumber = val
            this.getData()

        },
         //上传成功后查询列表数据
         async getData(status){
           
           this.loading = true
           let data = {
            //    batchId:this.batchId,
               limit:this.page.pageSize,
               page:this.page.pageNumber,
               noStatus:status?status:'0'

           }
           let res = await list(data)
           if(res.data.code == 200){
               if(this.importScussce){  //上传成功
                   this.tableData = res.data.data.list
                   this.total = res.data.data.totalCount / 1
                   if(res.data.data.totalCount == 0){
                       this.$message.warning('导入文件异常或数据为空，请重新上传')
                   }
               }else{                //上传失败
                   this.problemList = res.data.data.list
               }
               this.loading = false
           }
       },
        async success(response,file,fileList){
            if(response.code !==200) {
                this.$message.error(response.msg)
            }
            this.uploadNumber = 0
            this.batchId = response.data
            this.fileList = fileList
            this.uploadProgsess()
            this.uploadStatus(this.batchId) //查询上传状态
        },
        //上传比例
        uploadProgsess(){
            setTimeout(() => {
                if(this.uploadNumber<99){
                    this.uploadNumber++
                    this.uploadProgsess()
                }
            }, 10);  
        },
        //轮询上传状态
        async uploadStatus(id){
            let res = await queryById({batchId:id})
            if(res.data.code == 200){
                if(res.data.data.status == 0){  //正在处理中
                    setTimeout(() => {
                        this.uploadStatus(id)
                    }, 1000);
                }
                else if(res.data.data.status == 1){ //上传成功
                    this.uploadNumber = 100 
                    this.importScussce = true
                }else{                            //上传失败
                    this.importScussce = false
                    this.getData('1')
                }
                this.successNumber = res.data.data.successful//上传成功条数
                this.failNumber = res.data.data.failure//上传失败条数
            }
        },
        //重新上传
        newUpload(){  //恢复默认值
            this.stepIndex = 0
            this.total = 1
            this.importScussce = true
            this.fileList = []
            this.problemList = []
        },
        //文件超出
        exceed(){
            this.$message({
                message: '只允许上传一个文件',
                type: 'warning'
            })
        },
        change(){

        },
        //删除上传文件
        revList(){
            this.fileList = []
        },
        //下载模板 
        async download(){
            this.$axios.get(`${this.baseURL}module-account/accountImportBatch/downloadStandingTemplate`, {
                responseType:'blob',
                headers:{//上传请求头
                    Authorization:'Bearer '+window.localStorage.getItem('token')
                },
            }).then(Res=>{
                const content = Res.data;
                const blob = new Blob([content]);
                if ("download" in document.createElement("a")) {
                // 非IE下载
                const a = document.createElement("a");
                a.download = '账号模板.xlsx';
                a.style.display = "none";
                a.href = URL.createObjectURL(blob);
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(a.href); // 释放URL 对象
                document.body.removeChild(a);
                } else {
                // IE10+下载
                navigator.msSaveBlob(content, '账号模板.xlsx');
                }

            })
        },
    }
}
</script>

<style lang="scss" scoped>
.overall-page{
    .type{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        ::v-deep .progress.el-progress.el-progress--line{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            height: 50px;
            margin-top: 30px;
            .el-progress__text{
                position: absolute;
                margin: 0px;
                top: 0;
                height: 28px;
                font-size: 20px !important;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #257DFE;
            }
            .el-progress-bar{
                height: 8px;
                width: 360px;
            }
        }
    }
    .file-list{
        display: flex;
        align-items: center;
        height: 35px;
        width: calc(100% - 140px);
        margin: 30px 0px 0px 140px;
        img{
            height: 35px;
            width: 30px;
            margin-right: 10px;
        }
        .name-box{
            display: flex;
            flex-direction: column;
            height: 35px;
            width: 360px;
            .name-box-top{
                display: flex;
                height: 20px;
                .name-box-top-left{
                    display: flex;
                    height: 20px;
                    width: 80%;
                    .name{
                        height: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #257DFE;
                        line-height: 20px;
                        white-space: nowrap;
                    }
                    .size{
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #2B3F57;
                        line-height: 20px;
                        margin-left: 10px;
                    }
                }
                .proportion{
                    width: 20%;
                    display: flex;
                    justify-content: flex-end;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #737A94;
                    line-height: 20px;
                }
                
            }
            .progsess{
                width: 100%;
                border-radius: 2px;
                height: 4px;
                background: #257DFE;
                margin-top: 7px;
            }
        }
        .rev{
            height:14px;
            width:14px;
            cursor: pointer;
        }
    }
    .dialog{//弹框
        ::v-deep .el-dialog{ 
        width: 760px;
        background: #FFFFFF;
        border-radius: 10px;
        .el-dialog__header{
            background: #EDF2F5;
            border-radius: 10px 10px 0px 0px;
            .el-dialog__title{
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2B3F57;
            }
            
        }
        .el-dialog__body{
            padding: 28px 40px 0px 40px;
        }
        .el-dialog__footer{
            height: 70px;
            padding-top: 0px;
            margin-top: 27px;
            .dialog-footer{
                display: flex;
                justify-content: flex-end;
            }
            .el-button.el-button--default{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            width: 90px;
            height: 32px;
            background: rgba(37, 125, 254, 0);
            border-radius: 8px;
            border: 1px solid #1B79FF;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #257DFE;
            margin: 0px;
            }
            .el-button.el-button--primary{
            padding: 0;
            width: 90px;
            height: 32px;
            background: #257DFE;
            border-radius: 8px;
            margin-left: 30px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            }
        }
        }
    }
    .steps{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 14px;
        width: 100%;
        .steps-box{
            display: flex;
            height: 100px;
            align-items: center;
            .spot{
                height: 14px;
                width: 14px;
                border-radius: 50%;
                margin-right: 3px;
                background: #4CC8FF;
            }
            .spot-two{
                height: 10px;
                width: 10px;
                margin-right: 3px;
                border-radius: 50%;
                background: #E3E5EC;
            }
            .line{
                width: 64px;
                height: 1px;
                background: #4CC8FF;
            }
            .line-two{
                width: 125px;
                height: 1px;
                margin-right: 3px;
                border-bottom:#E3E5EC 1px dashed;
            }
            .line-three{
                width: 189px;
                height: 1px;
                background: #4CC8FF;
                margin-right: 3px;
            }
            .line-four{
                width: 189px;
                height: 1px;
                border-bottom:#E3E5EC 1px dashed;
                margin-right: 3px;
            }
        }  
          
    }
    .step-name{
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 10px;
        .name{
            display: flex;
            width: 208px;
            // margin-right: 152px;
            font-size: 14px;
            font-family: PingFang-SC-Heavy, PingFang-SC;
            font-weight: 800;
            color: #737A94;
            white-space: nowrap;
        }
        .name2{
            display: flex;
            width: 208px;
            font-size: 14px;
            font-family: PingFang-SC-Heavy, PingFang-SC;
            font-weight: 800;
            color: #2B3F57;
            white-space: nowrap;
        }
    }
    .template{
        display: flex;
        width: 680px;
        height: 140px;
        background: #FFFFFF;
        border: 1px solid #E3E5EC;
        margin-top: 30px;
        .template-left{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 140px;
            width: 120px;
            background: url(../../../assets/img/cutGraph/background.png) no-repeat center;
            cursor: pointer;
        }
        .template-right{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            height: 100%;
            padding-left: 20px;
            width: 560px;
            .text{
                display: flex;
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2B3F57;
                line-height: 20px;
                margin-top: 30px;
            }
            .tip{
                text-align: left;
                height: 37px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #737A94;
                line-height: 17px;
                margin: 11px 0px 00px 0px;
            }
            .download{
                text-align: left;
                width: 100px;
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #257DFE;
                line-height: 20px;
                cursor: pointer;
            }
        }
    }
    .table{ //表格
      display: flex;
      flex-direction: column;
      width: 100%;
      background: #FFFFFF;
      border: 1px solid rgb(236, 234, 234);
      margin-top: 30px;
    //   padding: 0px 20px 30px 20px;
      ::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell{ //斑马背景色
        background: #F5F8FF;
      } 
      ::v-deep .el-table__row td:nth-child(5){
        font-weight: bold;
      }
      ::v-deep.el-table--enable-row-hover .el-table__body tr.el-table__row:hover > td { //鼠标 浮入背景色
        background-color: #EAF8FF;
        cursor: pointer;
      }
    }
    ::v-deep .btn-next{
        margin-right: 0px;
    }
    .fail{
        display: flex;
        height: 100px;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 20px;
        border: 1px solid #E3E5EC;
        .tip{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100px;
            width: 100px;
            background: url(../../../assets/img/cutGraph/background2.png) no-repeat center;
        }
        .fail-right{
            display: flex;
            flex-direction: column;
            padding-left: 20px;
            height: 100px;
            width: calc(100% - 100px);
            .normal{
                display: flex;
                align-items: center;
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2B3F57;
                margin-top: 24px;
                span{
                    color: #53D39C;
                }
            }
            .abnormal{
                display: flex;
                align-items: center;
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2B3F57;
                margin-top: 12px;
                span{
                    color: #FF4D4F;
                }
            }
        }
    }
    .prompt{
        display: flex;
        flex-direction: column;
        padding: 0px 20px 20px 20px ;
        width: 100%;
        height: 200px;
        background: #F8FAFB;
        border: 1px solid #E3E5EC;
        overflow-x: hidden;
        overflow-y: auto;
        .text{
            display: flex;
            align-items: center;
            position: absolute;
            background: #F8FAFB;
            width: 640px;
            height: 50px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2B3F57;
            line-height: 20px;
            // margin-bottom: 10px;
        }
        .prompt-content{
            display: flex;
            width: 100%;
            font-size: 12px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #737A94;
            margin-bottom: 10px;
            .travel{
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                height: auto;
                width: 60px;
            }
            .problem{
                display: flex;
                flex-wrap: wrap;
                width: calc(100% - 60px);
            }
        }
    }
    .success{
        display: flex;
        justify-content: center;
        height: 160px;
        width: 100%;
        margin-top: 30px;
    }
    .tip-text{
        display: flex;
        justify-content: center;
        margin: 20px 0px 95px 0px;
        width: 100%;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #737A94;
        line-height: 20px;
    }
    
    .import-success{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 180px;
        margin: 30px 0px;
    }
    .over{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #257DFE;
        line-height: 28px;
        margin-bottom: 10px;
    }
    .info-num{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #737A94;
        line-height: 20px;
        margin-bottom: 105px;
    }
}

</style>